import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { NavigationItem } from '../models/navigation-item';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public updatedNavigationItems: Observable<NavigationItem[]>;

  private items: NavigationItem[] = [
    {
      title: 'Project details',
      route: 'project-details',
      active: true
    },
    {
      title: 'AzureDevOps Configuration',
      route: 'azure-dev-ops-configuration'
    },
    {
      title: 'Notifications',
      route: 'notifications',
      hidden: true
    },
    {
      title: 'Specify app type',
      route: 'specify-app-type'
    },
    {
      title: 'Configure mobile app',
      route: 'configure-mobile-app'
    },
    {
      title: 'Configure backend',
      route: 'configure-backend',
      hidden: true
    },
    {
      title: 'Configure frontend app',
      route: 'configure-web-app',
      hidden: true
    },
    {
      title: 'Final step',
      route: 'final-step'
    }
  ];

  private itemsSubject$: BehaviorSubject<NavigationItem[]> = new BehaviorSubject(this.getVisibleItems());

  constructor() {
    this.updatedNavigationItems = this.itemsSubject$.asObservable();
  }

  getVisibleItems(): NavigationItem[] {
    return this.items.filter(item => !item.hidden);
  }

  activateItem(title: string) {
    const currentItem: NavigationItem = this.items.find((item) => item.route === title);
    currentItem.active =  true;
    this.itemsSubject$.next(this.getVisibleItems());
  }

  activateItems() {
    for (let i = 1; i < this.items.length; i++) {
      if (!this.items[i].hidden) {
        this.items[i].active = true;
      }
    }
    this.itemsSubject$.next(this.getVisibleItems());
  }


  deactivateItems() {
    for (let i = 1; i < this.items.length; i++) {
      this.items[i].active = false;
    }
    this.itemsSubject$.next(this.getVisibleItems());
  }

  setItemVisibility(id: string, visibility: boolean) {
    const currentItem = this.items.find(item => item.route === id);
    currentItem.hidden = !visibility;

    this.itemsSubject$.next(this.getVisibleItems());
  }

  getPreviousRoute(currentItemRoute: string) {
    const currentItem = this.items.find(item => item.route === currentItemRoute);
    const itemIndex = this.items.indexOf(currentItem);
    for (let i = itemIndex - 1; i >= 0; i--) {
      if (!this.items[i].hidden) {
        return this.items[i].route;
      }
    }

    return null;
  }

  getNextRoute(currentItemRoute: string) {
    const currentItem = this.items.find(item => item.route === currentItemRoute);
    const itemIndex = this.items.indexOf(currentItem);
    if (itemIndex === this.items.length - 1) {
      return 'project-creation';
    }
    for (let i = itemIndex + 1; i < this.items.length; i++) {
      if (!this.items[i].hidden) {
        return this.items[i].route;
      }
    }

    return null;
  }
}
