import { Component, Input, AfterViewInit, OnInit, OnDestroy } from '@angular/core';

import { FormControlComponent, getValueAccessor } from '../../form-control-component.model';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dsc-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
  providers: [getValueAccessor(SelectFilterComponent)]
})
export class SelectFilterComponent extends FormControlComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() options: SelectOption[];
  @Input() isPaginationTop = false;
  @Input() customPlaceholder = 'Select';
  @Input() maxSelectedItems = null;
  @Input() optionTemplate = null;
  @Input() isDisabled = false;
  model: string | string[];
  filter = new FormControl('');
  filterSubscriber: Subscription;
  modelSubscriber: Subscription;
  isFocused: boolean;

  private originOptions: SelectOption[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.filterSubscriber = this.filter.valueChanges.subscribe(
      (value) => {
        this.options = this.originOptions.filter(
          (otp) => otp.name.toLowerCase().startsWith(value.toLowerCase())
        );
      }
    );

    this.modelSubscriber = this.modelChange.subscribe(
      () => { this.filter.setValue(''); }
    );
  }

  ngAfterViewInit(): void {
    this.originOptions = this.options;
  }

  ngOnDestroy(): void {
    this.filterSubscriber.unsubscribe();
    this.modelSubscriber.unsubscribe();
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
  }

  selectOption(option: SelectOption) {
    this.model = option.id;
    this.emitChanges();
  }

  isOptionSelected(option: SelectOption): boolean {
    return this.model.includes(option.id);
  }

  isOptionDisabled(option: SelectOption): boolean {
    return option.isDisabled;
  }

  get placeholder(): string {
    if (this.model !== null && this.originOptions.length > 0) {
      return this.originOptions.find(({ id }) => id === this.model).name;
    } else if (this.model !== null && this.options.length > 0) {
      return this.options.find(({ id }) => id === this.model).name;
    } else {
      return this.customPlaceholder;
    }
  }
}

interface SelectOption extends DictionaryItem {
  isDisabled?: boolean;
}
