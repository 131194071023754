import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';

import { pageSizeOptions } from '../config.model';

@Component({
  selector: 'dsc-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnInit, OnDestroy {
  @Input() params: PaginationParams;
  @Input() itemsCount: number;
  // tslint:disable-next-line:no-output-native
  @Output() change = new EventEmitter<PaginationParams>();
  pageSizeOptions: any[];
  pageSizeControl: FormControl;
  private isAlive = true;

  ngOnInit() {
    this.pageSizeOptions = pageSizeOptions.map((item) => ({id: item, name: String(item)}));
    this.pageSizeControl = new FormControl(this.params.pageSize);

    this.pageSizeControl.valueChanges
      .pipe((takeWhile(() => this.isAlive)))
      .subscribe((pageSize) => {
        this.params.pageSize = pageSize;
        this.params.pageNumber = 1;
        this.change.emit(this.params);
      });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  changePageNumber(page: number) {
    this.params.pageNumber = page;
    this.change.emit(this.params);
  }

  get pagesCount(): number {
    return Math.ceil(this.itemsCount / this.params.pageSize) || 1;
  }
}
