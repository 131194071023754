import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpServiceHelper } from './http-service-helper.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authUserInfo: any;

  constructor(
    private msalService: MsalService,
    private httpService: HttpServiceHelper,
    private configService: ConfigService
  ) { }

  getAuthUserInfo(): Observable<any> {
    if (this.authUserInfo) {
      return of(this.authUserInfo);
    } else {
      return this.httpService.httpGetRequest('https://graph.microsoft.com/v1.0/me/')
        .pipe(
          map ((response) => {
            this.authUserInfo = response;
            return response;
          })
        );
    }
  }

  getUsers(filter?: string): Observable<any> {
    if (filter) {
      return this.httpService.httpGetRequest(
        'https://graph.microsoft.com/v1.0/users?$filter='
          + `startswith(surname, '${filter}') or startswith(givenName, '${filter}') or startswith(mail, '${filter}')`
          + `&$top=50`
      );
    } else {
      return of({value: []});
    }
  }

  getUserById(id: string) {
    return this.httpService.httpGetRequest(`https://graph.microsoft.com/v1.0/users/${id}`)
      .pipe(
        map((profile) => {
          return profile;
        })
      );
  }

  getUserPhotoById(id: string) {
    return this.httpService.httpGetImageRequest(`https://graph.microsoft.com/v1.0/users/${id}/photo/$value`)
      .pipe(
        map((image) => {
          return {
            image
          };
        })
      );
  }

  checkUserPermission() {
    return this.httpService.httpPostRequest(
      `https://graph.microsoft.com/v1.0/me/checkMemberGroups`,
      { "groupIds": this.configService.config.accessGroups}
    )
      .pipe(
        map((response: any) => {
          return response.value;
        })
      );
  }

  gitPush(config: any, isEdited = false) {
    return this.httpService.httpPushGitRequest(config, isEdited)
      .pipe(
        map((response) => {
          return {
            response
          };
        })
      );
  }

  logout() {
    this.msalService.logout();
  }
}
