import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { ProjectCreationService } from '../services/project-creation.service';

@Component({
  selector: 'app-creation-progress',
  templateUrl: './creation-progress.component.html',
  styleUrls: ['./creation-progress.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreationProgressComponent implements OnInit {
  percent: number;

  constructor(private projectCreationService: ProjectCreationService) {
    this.projectCreationService.statusUpdated.subscribe((percent: number) => {
      this.percent = percent;
    });
  }

  ngOnInit() {
  }

}
