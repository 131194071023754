import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.authService.checkUserPermission().toPromise().then((value) => {
        if (value.length > 0) {
          return true;
        } else {
          this.router.navigate([403], {skipLocationChange: true});
          return false;
        }
    })
  }
}