import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { PermissionGuard } from './shared/guards/permission.guard';

export const routes: Routes = [
  {
    path: 'project-details',
    loadChildren: './project-details/project-details.module#ProjectDetailsModule',
    canActivate : [MsalGuard, PermissionGuard]
  },
  {
    path: 'azure-dev-ops-configuration',
    loadChildren: './azure-dev-ops-configuration/azure-dev-ops-configuration.module#VstsConfigurationModule',
    canActivate : [MsalGuard, PermissionGuard]
  },
  {
    path: 'notifications',
    loadChildren: './notification/notification.module#NotificationModule',
    canActivate : [MsalGuard, PermissionGuard]
  },
  {
    path: 'specify-app-type',
    loadChildren: './specify-app-type/specify-app-type.module#SpecifyAppTypeModule',
    canActivate : [MsalGuard, PermissionGuard]
  },
  {
    path: 'configure-mobile-app',
    loadChildren: './configure-mobile-app/configure-mobile-app.module#ConfigureMobileAppModule',
    canActivate : [MsalGuard, PermissionGuard]
  },
  {
    path: 'configure-web-app',
    loadChildren: './configure-web-app/configure-web-app.module#ConfigureWebAppModule',
    canActivate : [MsalGuard, PermissionGuard]
  },
  {
    path: 'configure-backend',
    loadChildren: './configure-backend/configure-backend.module#ConfigureBackendModule',
    canActivate : [MsalGuard, PermissionGuard]
  },
  {
    path: 'final-step',
    loadChildren: './final-step/final-step.module#FinalStepModule',
    canActivate : [MsalGuard, PermissionGuard]
  },
  {
    path: 'project-creation',
    loadChildren: './project-creation/project-creation.module#ProjectCreationModule',
    canActivate : [MsalGuard, PermissionGuard]
  },
  {
    path: '403',
    component: ForbiddenComponent,
    canActivate : [MsalGuard]
  },
  { path: '', redirectTo: '/project-details', pathMatch: 'full' },
  { path: '**', redirectTo: '/project-details', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
