import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { FormControlComponent, getValueAccessor } from '../../form-control-component.model';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [getValueAccessor(UsersListComponent)]
})
export class UsersListComponent extends FormControlComponent implements OnInit, AfterViewInit, OnDestroy {
  value = '';

  constructor(
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.model.map((user) => {
        this.value += user.displayName + '; ';
      });

      this.changeDetector.markForCheck();
    }, 0);
  }

  ngOnDestroy() {

  }
}
