import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { NavigationService } from '../services/navigation.service';
import { NavigationItem } from '../models/navigation-item';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnInit, OnDestroy {
  public items: NavigationItem[] = [];
  updatedNavigationItemsSub: Subscription;

  constructor(
    private navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.updatedNavigationItemsSub = this.navigationService.updatedNavigationItems.subscribe(items => {
      this.items = items;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.updatedNavigationItemsSub) {
      this.updatedNavigationItemsSub.unsubscribe();
    }
  }
}
