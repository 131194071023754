import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';

import { FormControlComponent, getValueAccessor } from '../../form-control-component.model';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProjectCreationService } from 'src/app/services/project-creation.service';

@Component({
  selector: 'dsc-project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss'],
  providers: [getValueAccessor(ProjectSelectorComponent)]
})
export class ProjectSelectorComponent extends FormControlComponent implements OnInit, OnDestroy {
  @Input() isPaginationTop = false;
  @Input() customPlaceholder = 'Select';
  @Input() maxSelectedItems = null;
  @Input() optionTemplate = null;
  @Input() isDisabled = false;
  @Output() chooseExistProject = new EventEmitter<string>();
  model: string;
  filter = new FormControl('');
  filterSubscriber: Subscription;
  isFocused: boolean;
  options: SelectOption[] = [];

  private originOptions: SelectOption[] = [];

  constructor(private projectCreation: ProjectCreationService) {
    super();

    this.projectCreation.getExistProjectNames().subscribe((projectNames) => {
      this.originOptions = projectNames;
    });
  }

  ngOnInit(): void {
    this.filterSubscriber = this.filter.valueChanges.subscribe(
      (value) => {
        if (this.model !== value) {
          this.writeValue(value);
          this.emitChanges();

          if (value.length > 0) {
            this.options = this.originOptions.filter(
              (otp) => otp.name.toLowerCase().startsWith(value.toLowerCase())
            );
          } else {
            this.options = [];
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.filterSubscriber.unsubscribe();
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;

    if (this.originOptions.find(option => option.id === this.model )) {
      this.getProjectData();
    }
  }

  selectOption(option: SelectOption) {
    this.writeValue(option.name);
    this.emitChanges();
    this.getProjectData();
  }

  isOptionSelected(option: SelectOption): boolean {
    return this.model === option.id;
  }

  getProjectData() {
    this.chooseExistProject.emit(this.model);
  }
}

interface SelectOption extends DictionaryItem {
  isDisabled?: boolean;
}
