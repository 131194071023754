import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'avaGenerator'
})
export class AvaGeneratorPipe implements PipeTransform {

    transform(value: string = '', lastName: string = '') {
        value = lastName ? (value || '') + ' ' + lastName : (value || '');
        value = value.trim().replace(/\s\s+/g, ' '); // Remove spaces on start/end. Replace multiple spaces to single
        if (value) {
            const caption = value.split(' ');
            if (value.indexOf('@') !== -1) {
                return caption[0][0].toUpperCase() + 'D';
            }
            return caption[0][0].toUpperCase() + (caption[1] ? caption[1][0].toUpperCase() : '');
        } else {
            return 'NN';
        }
    }
}
