import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceHelper {

  constructor(
    private http: HttpClient,
    private configService: ConfigService) {
  }

  public httpGetRequest(url: string) {
    return this.http.get(url)
      .map(response => {
        return response;
      })
      .catch(response => (Observable.throw(response)
      ));
  }

  public httpPostRequest(url: string, body: any) {
    return this.http.post(url, body)
      .map(response => {
        return response;
      })
      .catch(response => (Observable.throw(response)
      ));
  }

  public httpGetImageRequest(url: string) {
    return this.http.get(url, { responseType: 'blob' })
      .map(
        (response) => {
          return response;
        }
      )
      .catch(() => Observable.of(null));
  }

  public httpPushGitRequest(config: any, isEdited: boolean) {
    const getBranchesUrl = this.configService.config.azureGitApiUrl.replace('$requestType$', 'refs');
    const pushCommitUrl = this.configService.config.azureGitApiUrl.replace('$requestType$', 'pushes');
    const commitName = this.configService.config.commitName;
    const branchName = this.configService.config.branchName;

    return this.http.get(getBranchesUrl)
      .pipe(
        switchMap((branches: any) => {
          const branch = branches.value.find(b => b.name === branchName);
          return this.http.post(pushCommitUrl, {
            refUpdates: [
              {
                name: branch.name,
                oldObjectId: branch.objectId
              }
            ],
            commits: [
              {
                comment: commitName,
                changes: [
                  {
                    changeType: isEdited ? 'edit' : 'add',
                    item: {
                      path: `/Projects/${config.projectDetails.shortName}.json`
                    },
                    newContent: {
                      content: JSON.stringify(config),
                      contentType: 'rawtext'
                    }
                  }
                ]
              }
            ]
          }
        );
        })
      );
  }

  private getGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0;
      // tslint:disable-next-line:no-bitwise
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
