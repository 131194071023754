import { Component, OnInit, Input } from '@angular/core';
import { NavigationItem } from '../models/navigation-item';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss']
})
export class NavigationItemComponent implements OnInit {
  @Input() index: number;
  @Input() item: NavigationItem;

  constructor() { }

  ngOnInit() {
  }

}
