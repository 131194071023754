import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ConfigService {
  public config: Config;

  public init(config): Config {
    this.config = config;
    this.config.isProduction = config.environment === 'PRD';
    return this.config;
  }
}

export interface Config {
  clientID: string,
  tenant: string,
  portfolioUrl: string,
  azureGitApiUrl: string,
  azureBuildUrl: string,
  azureItemsUrl: string,
  azureItemsBatchUrl: string,
  commitName: string,
  branchName: string,
  branchShortName: string,
  accessGroups: string[],
  environment: 'BCP' | 'DEV' | 'PRD' | 'QAS' | 'STG' | 'SBX';
  isProduction: boolean;
}
