import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'dsc-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @Input() placeholder = 'Search';
  @Output() valueChange = new EventEmitter<string>();
  @Output() onfocus = new EventEmitter<string>();
  @Output() onblur = new EventEmitter<string>();
  @ViewChild('filterInput', {static: false}) filterInput: any;

  tagInputControl: FormControl;
  model: string;
  private isAlive = true;

  ngOnInit() {
    this.tagInputControl = new FormControl();
    this.tagInputControl.valueChanges
      .pipe(
        takeWhile(() => this.isAlive),
        debounceTime(500)
      )
      .subscribe(() => this.updateModel());
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  updateModel() {
    const value = this.tagInputControl.value;

    if (value.length !== 0 && value.length < 3) {
      return;
    }

    this.model = value;
    this.valueChange.emit(this.model);
  }

  focusInput() {
    this.filterInput.nativeElement.focus();
  }
}
