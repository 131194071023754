import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbTabsetModule,
  NgbTimepickerModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
  NgbCollapseModule,
  NgbProgressbarModule,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';

import { PaginationComponent } from './components/table/shared/pagination/pagination.component';
import { TableComponent } from './components/table/table.component';
import { SelectComponent } from './components/select/select.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { HeaderComponent } from './components/header/header.component';
import { UsersListSelectorComponent } from './components/users-list-selector/users-list-selector.component';

import { AvaGeneratorPipe } from './ava-generator.pipe';
import { SearchInputComponent } from './components/search-input/search-input.component';

import { AsyncImgDirective } from './async-img.directive';
import { UsersListComponent } from './components/users-list/users-list.component';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { ProjectSelectorComponent } from './components/project-selector/project-selector.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
  ],
  declarations: [
    PaginationComponent,
    TableComponent,
    SelectComponent,
    SelectFilterComponent,
    ProjectSelectorComponent,
    SpinnerComponent,
    HeaderComponent,
    UsersListSelectorComponent,
    UsersListComponent,
    SearchInputComponent,
    AvaGeneratorPipe,
    AsyncImgDirective
  ],
  exports: [
    PaginationComponent,
    TableComponent,
    SelectComponent,
    SelectFilterComponent,
    ProjectSelectorComponent,
    SpinnerComponent,
    HeaderComponent,
    UsersListSelectorComponent,
    UsersListComponent,
    SearchInputComponent,
    AvaGeneratorPipe,
    AsyncImgDirective,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
  ]
})

export class SharedModule {}
