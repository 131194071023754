import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FormControlComponent, getValueAccessor } from '../../form-control-component.model';

@Component({
  selector: 'dsc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [getValueAccessor(SelectComponent)]
})
export class SelectComponent extends FormControlComponent {
  @Input() options: SelectOption[];
  @Input() isMultiple = false;
  @Input() isPaginationTop = false;
  @Input() customPlaceholder = 'Select';
  @Input() maxSelectedItems = null;
  @Input() optionTemplate = null;
  @Input() isDisabled = false;
  model: string | string[];

  toggleOption(e: Event, option: SelectOption): void {
    const model = (<string[]>this.model);
    const index = model.indexOf(option.id);

    e.preventDefault();
    e.stopPropagation();

    if (index > -1) {
      model.splice(index, 1);
    } else {
      model.push(option.id);
    }

    this.emitChanges();
  }

  selectOption(option: SelectOption) {
    this.model = option.id;
    this.emitChanges();
  }

  isOptionSelected(option: SelectOption): boolean {
    return (<string[]>this.model).includes(option.id);
  }

  isOptionDisabled(option: SelectOption): boolean {
    return option.isDisabled ||
      (this.isMultiple &&
       this.maxSelectedItems !== null &&
       (<string[]>this.model).length === this.maxSelectedItems &&
       !this.isOptionSelected(option));
  }

  get placeholder(): string {
    const model = (<string[]>this.model);

    if (!this.isMultiple) {
      return this.model !== null && this.options.length > 0 ?
        this.options.find(({ id }) => (<string>this.model) === id).name :
        this.customPlaceholder;
    }

    return model.length > 0 ?
      this.options
        .filter(({ id }) => model.includes(id))
        .map(({ name }) => name)
        .join(', ') :
      this.customPlaceholder;
  }
}

interface SelectOption extends DictionaryItem {
  isDisabled?: boolean;
}
