import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Automated Project Start';
  authUserInfo: any;

  constructor(
    private authService: AuthService,
    public router: Router
  ) {
    this.authService.getAuthUserInfo().subscribe(
      (response: any) => {
        this.authUserInfo = response;
      }
    );
  }
}
