import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dsc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  routes: Routes;
  currentUser;

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.routes = [];

    this.authService.getAuthUserInfo().subscribe((response: any) => {
      this.currentUser = {
        id: response.id,
        email: response.email,
        firstName: response.givenName,
        lastName: response.surname,
        roles: [ 'admin' ],
        alias: response.userPrincipalName
      };
    });
  }

  logout() {
    this.authService.logout();
  }
}
