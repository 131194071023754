import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dsc-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
  @Input() isGlobal = false;
  @Input() diameter = 200;
}
