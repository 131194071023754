import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const cache: Map<string, string> = new Map();

@Directive({
  selector: '[dscAsyncImg]'
})
export class AsyncImgDirective implements OnChanges {
  @Input() dscAsyncImg: string;
  constructor(private http: HttpClient, private element: ElementRef) {}

  ngOnChanges() {
    const nativeElement = this.element.nativeElement;
    const isImage = nativeElement instanceof HTMLImageElement;

    if (isImage && this.dscAsyncImg) {
      nativeElement.setAttribute('src', URL.createObjectURL(this.dscAsyncImg));
    }
  }
}
