import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MsalModule, MsalInterceptor, BroadcastService, MsalService, MsalConfig } from '@azure/msal-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpServiceHelper } from './services/http-service-helper.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { NavigationComponent } from './navigation/navigation.component';
import { NavigationItemComponent } from './navigation-item/navigation-item.component';
import { CreationProgressComponent } from './creation-progress/creation-progress.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { PermissionGuard } from './shared/guards/permission.guard';
import { ConfigService } from './services/config.service';

export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read', 'group.read.all']],
  ['https://graph.microsoft.com/v1.0/users', ['user.read']],
  ['https://graph.microsoft.com/v1.0/me/people', ['user.read']]
];

export let config;

export function getConfig() {
  var request = new XMLHttpRequest();
  request.open('GET', 'assets/configs/config.json', false);
  request.send(null);
  const response = JSON.parse(request.responseText);
  config = response;
  let msalConfig: MsalConfig = new MsalConfig();
  msalConfig.clientID = response.clientID;
  msalConfig.authority = `https://login.microsoftonline.com/${response.tenant}/`;
  msalConfig.redirectUri = window.location.origin;
  msalConfig.cacheLocation = 'localStorage';
  msalConfig.protectedResourceMap = protectedResourceMap;
  protectedResourceMap.push([
    response.portfolioUrl,
    ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation']
  ]);
  msalConfig.navigateToLoginRequestUrl = true;
  msalConfig.popUp = false;
  return msalConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    NavigationItemComponent,
    CreationProgressComponent,
    ForbiddenComponent,
  ],
  imports: [
    NgbModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MsalModule.forRoot(getConfig())

  ],
  providers: [HttpServiceHelper,
    {provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: initFactory,
      deps: [ConfigService],
      multi: true
    },
    PermissionGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private msalService: MsalService,
    private broadcastService: BroadcastService,
    private configService: ConfigService
  ) {
    this.broadcastService.subscribe('msal:loginFailure', (payload) => {
      this.msalService.loginRedirect();
    });
  }
}

export function initFactory(configService: ConfigService) {
  return async () => {
    await configService.init(config);
  };
}
