import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { HttpServiceHelper } from './http-service-helper.service';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectCreationService {
  public statusUpdated: Observable<number>;
  public build: any;
  public progress: number;
  public isExistProject: boolean;

  private statusSubject$: BehaviorSubject<number> = new BehaviorSubject(0);
  private readonly pipelineId = 3183;

  constructor(
    private authService: AuthService,
    private httpService: HttpServiceHelper,
    private configService: ConfigService
  ) {
    this.statusUpdated = this.statusSubject$.asObservable();

    this.getStages();
  }

  getBuild() {
    if (this.build) {
      return of(this.build);
    } else {
      return this.httpService.httpGetRequest(
        'https://dev.azure.com/apps-its-us-deloitte/Mobile-Flex/_apis/build/builds'
          + `?definitions=${this.pipelineId}`
          + '&$top=10'
          + `&requestedFor=${this.authService.authUserInfo.mail}`
      )
        .pipe(
          map((response: any) => {
            const builds = response.value;
            const runningBuild = builds.find(build => build.status === 'notStarted' || build.status === 'inProgress');
            if (runningBuild) {
              this.build = runningBuild;
              return this.build;
            } else {
              this.statusSubject$.next(-1);
              throw new Error('Build was not found!');
            }
          })
        ).catch(() => {
          this.statusSubject$.next(-1);
          throw new Error('Getting build was with error!');
        });
    }
  }

  getStages() {
    return this.getBuild().pipe(
      switchMap(() => {
        return this.httpService.httpGetRequest(
          `${this.configService.config.azureBuildUrl}/${this.build.id}/Timeline`
        );
      }),
      map((response: any) => {
        let progress;
        const records = response.records;
        const stages: Stage[] = records.filter(record => record.type === 'Stage')
                                       .sort((a: Stage, b: Stage) => a.order - b.order);
        this.fillJobs(records, stages);

        const failedStage = stages.some((stage: Stage) => stage.result === 'failed');
        if (!failedStage) {
          const succeededStages = stages.filter((stage: Stage) => stage.result === 'succeeded');
          progress = Math.round(succeededStages.length * 100 / stages.length);
        } else {
          progress = -1;
        }
        this.progress = progress;
        this.statusSubject$.next(progress);
        return stages;
      })
    );
  }

  fillJobs(records, stages: Stage[]) {
    const phases: Phase[] = records.filter(record => record.type === 'Phase')
                                   .sort((a: Phase, b: Phase) => a.order - b.order);
    phases.forEach((phase) => {
      const currentStage: Stage = stages.find(stage => stage.id === phase.parentId);
      if (currentStage.phases) {
        currentStage.phases.push(phase);
      } else {
        currentStage.phases = [phase];
      }
    });
  }

  getExistProjectNames() {
    return this.httpService.httpPostRequest(
      this.configService.config.azureItemsBatchUrl,
        {
          includeContentMetadata: false,
          itemDescriptors: [{
            path: "/Projects",
            recursionLevel: 1,
            version: this.configService.config.branchShortName
          } ]
        }
    ).pipe(
      map((response: any) => {
        const projectNames = [];
        for (let i = 1; i < response.value[0].length; i++) {
          const projectName = response.value[0][i].path.split(/Projects\/(.*?).json/)[1];
          projectNames.push({id: projectName, name: projectName});
}
        return projectNames;
      })
    );
  }

  getPropertiesForExistProject(projectPath) {
    return this.httpService.httpGetRequest(
      `${this.configService.config.azureItemsUrl}?path=${projectPath}`
        + '&recursionLevel=0'
        + '&includeContentMetadata=true'
        + `&versionDescriptor.version=${this.configService.config.branchShortName}`
        + '&versionDescriptor.versionOptions=0'
        + '&versionDescriptor.versionType=0'
        + '&includeContent=true'
        + '&resolveLfs=true'
    );
  }
}
