import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { defaultSearchParams } from './shared/config.model';

interface TableRow {
  id?: string | number | symbol;
  code?: string | number | symbol;
  isArchived?: boolean;
  [key: string]: any;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dsc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit {
  @Input() items: TableRow[];
  @Input() itemsCount: number = null;
  @Input() config: TableConfig;
  @Input() isLoading: boolean;
  @Input() isPaginationEnabled = true;
  @Input() isCodeNavigationEnabled = false;
  @Input() showNoResults = true;
  @Input() cellOverflow: 'visible' | 'hidden' | 'auto' = 'hidden';
  @Input() searchParams: SearchParams;
  // tslint:disable-next-line:no-output-native
  @Output() change = new EventEmitter<SearchParams>();
  @Output() itemRemove = new EventEmitter();
  @Output() itemEdit = new EventEmitter();
  @Output() itemCancel = new EventEmitter();
  @Output() itemArchive = new EventEmitter<TableRow>();
  // TODO - rename extract -> unarchive
  @Output() itemExtract = new EventEmitter<TableRow>();

  columnWidths: string[] = [];

  constructor(private element: ElementRef) {  }

  ngOnInit() {
    if (this.itemsCount === null) {
      this.isPaginationEnabled = false;
    }

    this.setColumnWidths();

    if (this.isPaginationEnabled) {
      // Object.assign(this.searchParams, defaultSearchParams);
      for (const attrname in defaultSearchParams) {
        if (!this.searchParams[attrname]) {
          this.searchParams[attrname] = defaultSearchParams[attrname];
        }
      }
    }
  }

  changeSorting(field: string) {
    this.searchParams.sortDirection = this.searchParams.sortField !== field ?
      'descending' :
      this.searchParams.sortDirection === 'descending' ? 'ascending' : 'descending';
    this.searchParams.sortField = field;

    if (this.isPaginationEnabled) {
      this.searchParams.pageNumber = 1;
    }

    this.change.emit(this.searchParams);
  }

  changePagination(params: PaginationParams) {
    Object.assign(this.searchParams, params);
    this.change.emit(this.searchParams);
  }

  trackByFn(index: number, item: any) {
    return item.id || index;
  }

  private setColumnWidths() {
    const autoColumns = [];
    let remainedWidth = this.element.nativeElement.querySelector('table').offsetWidth;
    let calculatedWidthSum = 0;

    this.columnWidths = [];

    this.config.columns.forEach(({ width }, index) => {
      if (width) {
        this.columnWidths.push(`${width}px`);
        calculatedWidthSum += width;
      } else {
        autoColumns.push(index);
      }
    });

    if (this.config.actions) {
      const width = 80;

      this.columnWidths.push(`${width}px`);
      remainedWidth -= width;
    }

    remainedWidth -= calculatedWidthSum;

    autoColumns.forEach((index) => {
      this.columnWidths.splice(index, 0, `${remainedWidth / autoColumns.length}px`);
    });
  }
}
