import {
  Component,
  OnInit,
  HostBinding,
  HostListener,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription, forkJoin } from 'rxjs';
import { FormControlComponent, getValueAccessor } from '../../form-control-component.model';
import { SearchInputComponent } from '../search-input/search-input.component';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-users-list-selector',
  templateUrl: './users-list-selector.component.html',
  styleUrls: ['./users-list-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [getValueAccessor(UsersListSelectorComponent)]
})
export class UsersListSelectorComponent extends FormControlComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('search', {static: false}) searchInput: SearchInputComponent;
  @ViewChild('myDrop', {static: false}) myDrop: NgbDropdown;
  @HostListener('click')
  hostClicked() {
    this.searchInput.focusInput();
  }

  getUsersSubscriber: Subscription;
  isLoaded = false;
  isUserListLoaded = true;
  isSelectOpened = undefined;
  selectedUsers = [];
  foundedUsers = [];
  isFocused: boolean;

  private previousModel;

  constructor(
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (JSON.stringify(this.previousModel) === JSON.stringify(this.model)) {
      return;
    }

    this.previousModel = this.model;
    this.isLoaded = false;
    this.changeDetector.markForCheck();
   
    const observablesList = [];

    if (this.model.length === 0) {
      setTimeout(() => {
        this.isLoaded = true;
        this.changeDetector.markForCheck();
      }, 0);
      return;
    }

    this.model.map((id) => {
      observablesList.push(this.authService.getUserById(id));
    });

    forkJoin(observablesList).subscribe(
      (results) => {
        results.map((res: any) => {
          this.addSelectedUser(res);

          res.cropModel$ = this.authService.getUserPhotoById(res.mail);
        });

        this.isLoaded = true;
        this.changeDetector.markForCheck();
      }
    );
  }

  addSelectedUser(selectedUser) {
    if (!this.selectedUsers.some((user) => user.mail === selectedUser.mail)) {
      this.selectedUsers.push(selectedUser);
    }
    if (!this.model.some((id) => id === selectedUser.mail)) {
      this.model.push(selectedUser.mail);
    }
  }

  userSelected(selectedUser): void {
    this.addSelectedUser(selectedUser);
    this.searchInput.tagInputControl.setValue('');

    this.emitChanges();
    this.changeDetector.markForCheck();
    this.searchInput.focusInput();
  }

  userDeselected(event: Event, deselectedUser): void {
    this.myDrop.close();
    this.selectedUsers = this.selectedUsers.filter((user) => user.mail !== deselectedUser.mail);
    this.model = this.model.filter((id) => id !== deselectedUser.mail);
    this.emitChanges();
    event.stopPropagation();
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
  }

  onSearchInputChanged(value: string): void {
    this.myDrop.open();

    this.isUserListLoaded = false;
    if (this.getUsersSubscriber) {
      this.getUsersSubscriber.unsubscribe();
    }
    this.foundedUsers.map((user) => {
      if (user.imageSubscriber) {
        user.imageSubscriber.unsubscribe();
      }
    });
    this.foundedUsers = [];

    this.getUsersSubscriber = this.authService.getUsers(value).subscribe(
      (result) => {
        this.isUserListLoaded = true;

        result.value.map((user) => {
          user.imageSubscriber = this.authService.getUserPhotoById(user.mail).subscribe(
            (image) => {
              const currentUser = this.foundedUsers.find((current) => current.mail === user.mail);
              currentUser.image = image.image;
              if (currentUser.image) {
                this.changeDetector.markForCheck();
              }
            },
            () => user.image = null,
          );
        });

        this.foundedUsers = result.value;

        this.changeDetector.markForCheck();
      }
    );
  }

  ngOnDestroy() {
    if (this.getUsersSubscriber) {
      this.getUsersSubscriber.unsubscribe();
    }
    this.foundedUsers.map((user) => {
      if (user.imageSubscriber) {
        user.imageSubscriber.unsubscribe();
      }
    });
  }
}
